<template>
    
    <div class="page overflow-hidden bg-background">
      
            <div class="flex items-center justify-center h-screen overflow-hidden">
                <slot />
            </div>

  </div>
</template>
